<template>
  <div class="confirm-page form-group">

<!--    <section class="mb-5">-->
<!--&lt;!&ndash;      <credit-card-select v-model="card" :is-block="true"/>&ndash;&gt;-->
<!--      <div class="primary-block d-flex align-items-center">-->
<!--        <div class="icon-box me-3">-->
<!--          <icon icon-name="halyk-icon"></icon>-->
<!--        </div>-->
<!--        <div>-->
<!--          <p class="primary-text">{{$t('insurancePolicy')}}</p>-->
<!--          <p class="secondary-text">{{$t('halykIC')}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <form-confirm-info class="mb-5" :termsAgree="i_agree" @changeTermsAgree="changeTermsAgree" :info="info"/>

    <button class="primary-btn bottom-page-btn" @click="$emit('submit')" :disabled="!i_agree">
      {{$t('confirm')}}
    </button>
  </div>
</template>

<script>
import FormConfirmInfo from "../FormConfirmInfo";
import CreditCardSelect from "../BuyPolicy/CreditCardSelect";

export default {
  name: "OtpConfirm",
  props: {
    info: {
      type: Object
    },
    calculationData: null
  },
  components: {
    FormConfirmInfo,
    CreditCardSelect
  },
  data() {
    return {
      i_agree: true,
      card: null
    }
  },
  mounted() {
  },
  computed: {
    cardIcon() {
      const { currentCard } = this;

      if (!currentCard) {
        return 'wallet-icon'
      }

      if(currentCard && currentCard.type === 'VISA') {
        return 'visa-icon'
      }
      else if (currentCard && currentCard.type === 'MASTERCARD') {
        return 'master-card-icon'
      }
      else {
        return 'wallet-icon'
      }
    }
  },
  methods: {
    changeTermsAgree(val) {
      this.i_agree = val;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
